import React from 'react';
import './index.css';

const HelpModal = ({ text }) => (
  <div className="helpModal-container">
    {text}
  </div>
)

export default HelpModal;
