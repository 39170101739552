import React from 'react';
import Main from './containers/main';
import RequestPage from './containers/requestPage';
import ScrollToTop from './containers/scrollToTop';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import basicAuth from './containers/basicAuth';
import SwaggerCustomUI from './containers/swagger';

const AppRouter = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route exact path="/cb" component={RequestPage} />
      <Route exact path="/swagger" component={SwaggerCustomUI} />
      <Route exact path="/" component={Main} />
      <Route exact path="/basic-authentication" component={basicAuth} />
    </Switch>
  </Router>
);

export default AppRouter;
