import React, { useEffect } from 'react';
import queryURLParser from '../../utils/queryURLParser';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';
import './index.css';

const RequestPage = ({ location }) => {
  const history = useHistory();
  const parsedQuery = queryURLParser(location.search);
  let query = Object.keys(parsedQuery)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(parsedQuery[k]))
    .join('&');

  useEffect(() => {
    const getToken = () => {
      fetch(`http://18.157.231.45:3004/v1/callback?${query}`)
        .then((response) => {
          if (!response.error && response.status !== 400) {
            return response.json();
          }
          return null;
        })
        .then((response) => {
          if (response) {
            const blob = new Blob([JSON.stringify(response)], { type: 'application/json' });
            saveAs(blob, 'credentials.json');
          }
          return history.push('/');
        });
    };
    getToken();
  }, [query, history]);

  return <div className="loader-container">Loading...</div>;
};

export default RequestPage;
