import { EXTERNAL_API } from '../../config';

export const END_POINTS = [
  {
    name: 'GATEWAY',
    endpointDescription:
      'This API will be used for getting detailed information about the gateway by sm_id(Solar Manager ID).',
    method: 'GET',
    url: `${EXTERNAL_API}/v1/info/gateway/:sm_id`,
    response: `{
    "_id": "5c8fb8e7cdcda169da9d5fe3", // {string} - db id for gateway
    "signal": "connected", // {string} - gateway signal
    "name": "1234123412341234", // {string} - gateway name in system
    "sm_id": "1234123412341234", // {string} - gateway unique id
    "owner": "5c8fb8fccdcda169da9d5fe4", // {string} - id of user - owner of gateway
    "firmware": "0.20.1", // {string} - gateway firmware version
    "lastErrorDate": "2021-01-20T06:00:01.150Z", // {string} - date of last error
    "mac": "2C:3E:51:C5:A0:CA", // {string} - gateway mac address
    "ip": "192.168.1.51" // {string} - gateway ip
  }`
  },
  {
    name: 'SENSOR',
    endpointDescription:
      'Get information about user sensors (devices) such as water heaters, smart plugs etc.',
    method: 'GET',
    description:
      'Response will contain general data about sensors(devices). Response type JSON.',
    url: `${EXTERNAL_API}/v1/info/sensors/:sm_id`,
    response: `[
      {
          "_id": "5da07dc5d32a997fd7fb80aa", // {string} - sensor(device) id in the system
          "priority": 7, // {number} - priority of the device
          "device_type": "device", // {string} - device type
          "signal": "connected", // {string} - connection status of the sensor - “connected” or “not connected”.
          "type": "Water Heater", // {string} - the type of sensor(device)
          "device_group": "myPV AC THOR", // {string} - group of the devices
          "ip": "78.45.12.130", // {string} - IP address of the device in the local network
          "tag": null, // {object | null} - the device tag
          "createdAt": "2019-10-16T11:23:43.229Z",
          "updatedAt": "2020-10-20T11:31:14.842Z"
      },
      {
          "_id": "5da6fdbf6f9aab5013a5cb9f",
          "priority": 5,
          "device_type": "device",
          "signal": "connected",
          "device_group": "KEBA Wallbox P30",
          "type": "Car Charging",
          "createdAt": "2019-10-16T11:23:43.229Z",
          "updatedAt": "2020-10-20T11:31:14.842Z",
          "tag": {
              "_id": "5dfb86da1cdd557b82303f92",
              "name": "Tag_1"
          },
          "ip": "1.5.5.10"
      }
    ]`
  },
  {
    name: 'GET SINGLE SENSOR',
    method: 'GET',
    description:
      'The response will contain general data about sensors(devices). Response type JSON.',
    url: `${EXTERNAL_API}/v1/info/sensor/:sensorId`,
    response: `{
        "_id": "5da07dc5d32a997fd7fb80aa", // {string} - sensor(device) id in the system
        "priority": 7, // {number} - priority of the device
        "device_type": "device", // {string} - device type
        "signal": "connected", // {string} - connection status of the sensor - “connected” or“not connected”.
        "type": "Water Heater", // {string} - the type of sensor(device)
        "device_group": "myPV AC THOR", // {string} - group of the devices
        "ip": "78.45.12.130", // {string} - IP address of the device in the local network
        "createdAt": "2019-10-11T13:04:05.719Z", // {string} - time of creating the device
        "updatedAt": "2020-02-20T11:46:12.358Z", // {string} - time of last updating the device
        "tag": null, // {object | null} - the device tag
        "data": // {object} - configuration object, specific for each device
      }`
  },
  {
    name: 'DATA',
    method: 'GET',
    url: `${EXTERNAL_API}/v1/stream/gateway/:sm_id`,
    description:
      'The response will contain general values of production, consumption, and battery and array with the latest values of temperature, battery, and power for each device. This will be raw data received from the gateway.',
    response: `{
          "Interface Version": "1.0",
          "TimeStamp": "2021-02-01T16:09:39.744Z",
          "currentBatteryChargeDischarge": 0,
          "currentPowerConsumption": 494,
          "currentPvGeneration": 0,
          "devices": [
            {
              "_id": "5e07c29ecb03704972e486cc",
              "accumulatedErrorCount": 0,
              "currentPowerInvSm": 0,
              "currentEnergy": 0,
              "errors": [],
              "signal": "connected"
            },
            {
              "_id": "5e08ab9ccb03704972029a2c",
              "accumulatedErrorCount": 6,
              "currentPowerInvSm": 0,
              "errors": [],
              "signal": "connected"
            },
            {
              "_id": "5e0cd6f6dde8943e7179ebda",
              "accumulatedErrorCount": 0,
              "activeDevice": 0,
              "currentPower": 0,
              "errors": [],
              "signal": "connected",
              "switchState": 1
            },
            {
              "_id": "5f7d950deb88166c81c56f7a",
              "accumulatedErrorCount": 34,
              "activeDevice": 0,
              "currentPower": 0,
              "currentWaterTemp": 44,
              "errors": [],
              "signal": "connected",
              "status": 0
            },
            {
              "SOC": 0,
              "_id": "5d604d02b364481c2e0c72b5",
              "accumulatedErrorCount": 0,
              "activeDevice": 0,
              "currentPower": 0,
              "errors": [],
              "signal": "connected"
            },
            {
              "_id": "5ef0fe7cb9c6c4306c885133",
              "accumulatedErrorCount": 2067,
              "activeDevice": 0,
              "currentPower": 0,
              "errors": [
                "1"
              ],
              "signal": "not connected",
              "switchState": 0
            },
            {
              "_id": "5d875f92f41d1c0df7b2ca7f",
              "accumulatedErrorCount": 2067,
              "activeDevice": 0,
              "currentPower": 0,
              "currentWaterTemp": 0,
              "errors": [
                "5"
              ],
              "signal": "not connected"
            }
          ],
          "errors": [],
          "soc": 0
        }`
  },
  {
    name: 'CONSUMPTION STATISTIC FOR SENSOR',
    method: 'GET',
    description:
      'The response will contain the ’totalConsumption’ field as total consumed power per period of time and consumption grouped by days(included into this period).',
    url: `${EXTERNAL_API}/v1/consumption/sensor/:sensorId?period=day`,
    params: 'sensorId: _id of the sensor',
    query: [{
      name: 'period: some period of time for export',
      data: ['day', 'week', 'month']
    }],
    response: `{
          "sensorId": "5da6fdbf6f9aab5013a5cb9f",
          "period": "day",
          "data": [
              {
                  "createdAt": "2021-01-01",
                  "consumption": 120.83333429999996
              }
          ],
          "totalConsumption": 120.83333429999996
        }`
  },
  {
    name: 'SENSOR CONSUMPTION STATISTICS',
    method: 'GET',
    description:
      'The response will contain the ’totalConsumption’ field as total consumed power per period of time and consumption will be grouped by days(included into this period).',
    url: `${EXTERNAL_API}/v1/consumption/gateway/:id?period=day`,
    params: 'id: sm_id of gateway',
    query: [{
      name: 'period: some period of time for export',
      data: ['day', 'week', 'month']
    }],
    response: `{
          "gatewayId": "5c8fb8e7cdcda169da9d5fe3",
          "period": "day",
          "data": [
              {
                  "createdAt": "2021-04-13",
                  "consumption": 0, // {number} - power consumption in W
                  "production": 0 // {number} - power production in W
              }
          ],
          "totalConsumption": 0 // {number} - total power consumption in W
        }`
  },
  {
    name: 'GET LAST DATA FROM GATEWAY',
    method: 'GET',
    description:
      'The response will contain the timestamp of the last gateway update and the gateway data for that date.',
    url: `${EXTERNAL_API}/v1/data/gateway/:id`,
    params: 'id: _id of the gateway',
    response: `{
          "Interface_Version": "1.0",
          "TimeStamp": "2021-04-07T15:24:55.608Z",
          "currentPowerConsumption": 5000, // {number} - power consumption in W
          "currentPvGeneration": 20000, // {number} - generated power in W
          "currentBatteryChargeDischarge": 0, // {number} - power discharge in W
          "soc": 43, // {number} - battery capacity
          "devices": [
              {
                  "_id": "5dfccd301cdd557b8231922f",
                  "signal": "connected",
                  "currentPower": -100, // {number} - power of device in W
                  "SOC": 25 // {number} - battery capacity
              },
              {
                  "_id": "5db9b0bc2b591c467a947428",
                  "signal": "connected",
                  "currentPower": 1, // {number} - power of device in W
                  "currentWaterTemp": 25, // {number}
                  "errors": [
                      "2"
                  ]
              }
          ]
        }`
  },
  {
    name: 'GET LAST DATA FROM SENSOR',
    method: 'GET',
    description:
      'The response will contain date of last sensor update and sensor`s data for this date. In object ‘data’ will be contained sensor`s signal, power, water temperature or SOC and array of sensor`s errors.',
    url: `${EXTERNAL_API}/v1/stream/sensor/:smId/:sensorId`,
    params: `smId: sm_id of gateway
        sensorId: _id of sensor`,
    response: `{
          "date": "2021-03-25T09:26:37.037Z",
          "data": {
                  "_id": "5db9b0bc2b591c467a947428",
                  "signal": "connected", //string
                  "currentPower": 1, //number
                  "currentWaterTemp": 26, //number
                  "errors": [
                          "1", //string
                          "15",
                          "10"
                  ]
          }
        }`
  },
  {
    name: 'GET PIE CHART FOR GATEWAY',
    method: 'GET',
    description:
      'The response will contain common data from gateway’ and detailed data for each device if ‘sensorData’ object.',
    url: `${EXTERNAL_API}/v1/chart/gateway/:id`,
    params: 'id: _id of gateway',
    response: `{
          "lastUpdate": "2021-04-07T15:24:55.608Z",
          "production": 20000, // {number} - production in W
          "consumption": 5000, // {number} - consumption in W
          "battery": {
              "capacity": 43,
              "batteryCharging": 0,
              "batteryDischarging": 0
          },
          "arrows": [
              {
                  "direction": "fromPVToGrid",
                  "value": 15000 // {number} - power in W
              },
              {
                  "direction": "fromGridToConsumer",
                  "value": 0 // {number} - power in W
              },
              {
                  "direction": "fromPVToConsumer",
                  "value": 5000 // {number} - power in W
              }
          ]
        }`
  },
  {
    name: 'Get forecast for gateway',
    method: 'GET',
    description:
      'The response will contain a forecast for the device for the next 3 days.',
    url: `${EXTERNAL_API}/v1/forecast/gateways/:id`,
    params: 'id: id of the device',
    response: `[
          {
              "timestamp": 1641808800000, // {number} - timestamp in milliseconds
              "expected": 1726, // {number} - power in W
              "min": 1183, // {number} - power in W
              "max": 2269 // {number} - power in W
          },
          {
              "timestamp": 1641809700000,
              "expected": 1638,
              "min": 1120,
              "max": 2155
          },
       ]`
  },
  {
    name: 'Get Low Rate Tariff',
    method: 'GET',
    description: "The response will contain a user's low rate tariff setting.",
    url: `${EXTERNAL_API}/v1/low-rate-tariff/gateways/:sm_id`,
    params: 'sm_id: sm_id of the gateway',
    response: `{
      "Monday_Friday_from": "20:00", // {string} - time
      "Monday_Friday_to": "07:00",
      "Saturday_from": "13:00",
      "Saturday_to": "07:00",
      "Sunday_from": "00:00",
      "Sunday_to": "07:00"
    }`
  },
  {
    name: 'Set car charger and car charging system mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/car-charger/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "chargingMode": 3, // int, min 0, max 4
      "constantCurrentSetting": 6 // int, min 6A, max 32A
    }`,
    info: {
      name: 'chargingMode',
      data: [
        '0 = {Fast Charge}',
        `\xa01 = {Only solar}`,
        '2 = {Solar & low tariff}',
        '3 = {Do not charge}',
        '4 = {Constant current}',
        '5 = Minimal & Solar'
      ]
    }
  },
  {
    name: 'Set v2x car-charger mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/v2x/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "chargingMode": 2,  // int, min 0, max 4
      "minimalSocAvailable": 78, // int min 0, max 100
      "maximalSocAvailable": 95, //  int min 0, max 100, must be bigger than minimalSocAvailable
      "constantCurrentSetting": 25 // int, min 6A, max 32A
    }`,
    info: {
      name: 'chargingMode',
      data: [
        '0 = {Fast Charge}',
        `\xa01 = {Only solar}`,
        '2 = {Solar & low tariff}',
        '3 = {Do not charge}',
        '4 = {Constant current}'
      ]
    }
  },
  {
    name: 'Set switch mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/switch/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "chargingMode": 3 // int, min 1, max 5
    }`,
    info: {
      name: 'chargingMode',
      data: [
        '0 = {noMode}',
        '\xa01 = {ON}',
        '2 = {OFF}',
        '3 = {Only Solar}',
        '4 = {Solar & low tariff}',
        '5 = {No control}'
      ]
    }
  },
  {
    name: 'Set smart plug mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/smart-plug/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "chargingMode": 3 // int, min 1, max 5
    }`,
    info: {
      name: 'chargingMode',
      data: [
        '\xa01 = {ON}',
        '2 = {OFF}',
        '3 = {Only Solar}',
        '4 = {Solar & low tariff}',
        '5 = {No control}'
      ]
    }
  },
  {
    name: 'Set water heater mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/water-heater/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "heatModeAuto": false, // bollean
      "heaterstep": 5, // int, only for ASKOHEAT+
      "powerSettingPercent": 99 // int
    }`
  },
  {
    name: 'Set heatpump mode',
    method: 'PUT',
    description: 'Status code 204',
    url: `${EXTERNAL_API}/v1/control/heat-pump/:sensorId`,
    params: 'sensorId: id of the smart sensor(device)',
    response: `{
      "chargingMode": 1 // int
    }`,
    info: {
      name: 'chargingMode',
      data: [
        '\xa01 = {OptimizationOn}',
        '2 = {noControl}'
      ]
    }
  },
  {
    name: 'Get statistics of the gateway',
    method: 'GET',
    description: '',
    url: `${EXTERNAL_API}/v1/statistics/gateways/:sm_id`,
    params: 'sm_id: sm_id of gateway',
    query: [
      {
        name: 'accuracy',
        data: [
          'low; // we recommend using this accuracy for huge ranges bigger than 1 month',
          'medium; // we recommend using this accuracy for medium ranges, till 1 month',
          'high; // we recommend using this accuracy for small ranges, till 1 week'
        ]
      },
      {
        name: 'from',
        data: [
          'any valid iso date like 2022-01-11T00:00:00.000Z'
        ]
      },
      {
        name: 'to',
        data: [
          'any valid iso date like 2022-01-12T00:00:00.000Z'
        ]
      }
    ],
    response: `{
      "consumption": 31873.29, // double in Wh
      "production": 68160.89, // double in Wh
      "selfConsumption": 22473.75, // double in Wh
      "selfConsumptionRate": 32.97, //  double in %
      "autarchyDegree": 70.51 //  double in %
    }`
  },
  {
    name: 'Get sensor tariff',
    method: 'GET',
    url: `${EXTERNAL_API}/v1/control/tariff`,
    query: [
      {
        name: 'sensorId',
        data: [
          'id of the sensor'
        ]
      },
      {
        name: 'from',
        data: [
          'any valid iso date like 2022-01-11T00:00:00.000Z'
        ]
      },
      {
        name: 'to',
        data: [
          'any valid iso date like 2022-01-12T00:00:00.000Z'
        ]
      }
    ],
    response: `[
      {
        "_id": "6269373448595eb6ccb5349a",
        "CurrentEnergyPurchaseTariff1": 123,
        "CurrentEnergyDeliveryTariff1": 123,
        "CurrentEnergyPurchaseTariff2": 125,
        "CurrentEnergyDeliveryTariff2": 125,
        "createdAt": "2022-04-27T12:30:00.000Z",
        "updatedAt": "2022-04-27T12:30:00.000Z",
        "__v": 0
      }
  ]`
  }
];
