import React from 'react';
import TopBar from '../../components/TopBar';
import authorize from '../../utils/basicAuthorization';
import SwaggerUI from "swagger-ui-react";

import "swagger-ui-react/swagger-ui.css";
import './index.scss';

const SwaggerCustomUI = () => {
  return (
    <>
      <TopBar authorize={() => authorize()} />
      <SwaggerUI
        docExpansion="list"
        filter
        url="https://dev-cloud.solar-manager.ch/swagger.json"
      />
    </>
  );
};

export default SwaggerCustomUI;
