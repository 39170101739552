import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import EndPointBlock from '../../components/EndPointBlock';
import HelpModal from '../../components/HelpModal';
import { END_POINTS } from './constants';
import authorize from '../../utils/basicAuthorization';
import './index.css';

const Main = () => {
  const [copied, copy] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => copy(false), 1000);
    }
  }, [copied]);

  return (
    <div>
      <TopBar authorize={() => authorize()} />
      {copied && <HelpModal text="Copied!" />}
        <p className="swagger-banner">
          This API description is not maintained any more.
          The new one is available under the following <Link to="/swagger">link.</Link>
        </p>
      <div className="content-container">
        <p className="content-description">
          Solar Manager supports basic authentication. For more information on how to use basic authorization, click <Link to="/basic-authentication">here.</Link>
        </p>
        <section className="sub-chapter">
          <hr />
          <h2>Do API calls</h2>
          <p className="info-description">Your App can use the token to authorize calls to the API by sending it as the HTTP Authorization header:</p>
          <div className="steps-container">
            <div className="steps">
              <p className="lh-20">Authorization: Basic &lt;credentials&gt;</p>
            </div>
          </div>
        </section>
        {END_POINTS.map((endPoint) => (
          <EndPointBlock
            key={endPoint.name}
            name={endPoint.name}
            endpointDescription={endPoint.endpointDescription}
            method={endPoint.method}
            url={endPoint.url}
            description={endPoint.description}
            response={endPoint.response}
            copy={copy}
            query={endPoint.query}
            params={endPoint.params}
            info={endPoint.info}
          />
        ))}
      </div>
    </div>
  );
};

export default Main;
