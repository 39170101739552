import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './index.css';

const EndPointBlock = ({
  name,
  endpointDescription,
  method,
  url,
  description,
  response,
  copy,
  query,
  params,
  body,
  info
}) => {
  let brackets = 0;
  const parsedResponse =
    response &&
    response
      .split('\n')
      .filter(Boolean)
      .map((el) => {
        const line = el.trim();
        let marginLeft = '';
        if (/^[{[].*/.test(line) || /.*[{[]$/.test(line)) {
          brackets += 1;
          if (brackets > 0) {
            marginLeft = `ml-${(brackets - 1) * 30}`;
          }
        }
        if (/^[}\]].*/.test(line) || /.*[}\]]$/.test(line)) {
          brackets -= 1;
          if (brackets > 0) {
            marginLeft = `ml-${brackets * 30}`;
          }
        }
        if (brackets && !marginLeft) {
          marginLeft = `ml-${brackets * 30}`;
        }
        if (marginLeft) {
          return <p className={marginLeft}>{el}</p>;
        }
        return <p>{el}</p>;
      });
  return (
    <section className="endpoint-container">
      <hr />
      <h2 className="endpoint-name">{name}</h2>
      <p className="endpoint-description">{endpointDescription}</p>
      <div className="endpoint-header-container">
        <div className="method">{method}</div>
        <div className="url">{url}</div>
      </div>
      <div className="endpoint-body-container">
        <div className="title">
          {method === 'GET' ? (
            <>
              <p>Response</p>
              <CopyToClipboard text={response} onCopy={() => copy(true)}>
                <span>Copy Response</span>
              </CopyToClipboard>
            </>
          ) : (
            <p>Body</p>
          )}
        </div>
        {method === 'GET' && <p className="description">{description}</p>}
        <div className="response">{parsedResponse}</div>
        {info && (
          <>
            <div className="title">
              <p>Info</p>
            </div>
            <p className="description fieldName">{info.name}</p>
            <ul>
              {info.data.map(el => (
                <li key={el}>{el}</li>
              ))}
            </ul>
          </>
        )}
        {query && (
          <>
            <div className="title">
              <p>Query</p>
            </div>
            {query.map(({ name, data }) => (
              <>
                <p className="description fieldName">{name}</p>
                <ul>
                  {data.map((value) => (
                    <li key={name}>{value}</li>
                  ))}
                </ul>
              </>
            ))}
          </>
        )
        }
        {params && (
          <div className="params">
            <span>Params</span>
            <p className="description">{params}</p>
          </div>
        )}
        {method === 'PUT' && (
          <>
            <div className="title">
              <p>Response</p>
            </div>
            <p className="description">{description}</p>
          </>
        )}
      </div>
    </section>
  );
};

export default EndPointBlock;
