import React from 'react';
import TopBar from '../../components/TopBar';
import authorize from '../../utils/basicAuthorization'
import { EXTERNAL_API } from '../../config';

const basicAuth = () => {
  return (
    <>
      <TopBar authorize={() => authorize()}/>
      <div className="content-container">
        <section className="chapter">
          <h2 className="chapter-header">Solar Manager API Basic Authentication</h2>
          <section className="sub-chapter">
            <hr />
            <h2>URL {EXTERNAL_API}</h2>
            <p className="info-description">The HTTP request should contain a header field in the form of <span>Authorization: Basic &lt;credentials&gt;</span>, where credentials is the <span>Base64</span> encoding of username and password joined by a single colon :</p>
          </section>
          <section className="sub-chapter">
            <hr />
            <h2>cURL Basic Authentication Example</h2>
            <div className="endpoint-body-container">
              <div className="example">
                <p>Example</p>
              </div>
              <div className="response">
                <p>curl --location --request GET '{EXTERNAL_API}' \ --header 'Authorization: Basic YWRtaW5AaW5kZWVtYS5jb206MTIzcXdlYXNkenhj'</p>
              </div>
            </div>
          </section>
          <section className="sub-chapter">
            <hr />
            <h2>Postman Basic Authentication Example</h2>
            <p className="info-description">1. Choose Authorization Tab</p>
            <figure className="img-container mb-40">
              <img src="./images/choose-authorization-tab.png" alt="choose-authorization-tab" />
              <figcaption>Choose Authorization Tab</figcaption>
            </figure>
            <p className="info-description">2. Choose Authorization Type Basic Auth and fill out Username and Password fields</p>
            <figure className="img-container mb-40">
              <img src="./images/choose-authorization-type.png" alt="choose-authorization-type" />
              <figcaption>Choose Authorization Type Basic Auth and fill out Username and Password fields</figcaption>
            </figure>
            <p className="info-description">3. Choose Headers Tab and expect to see Authorization field among headers</p>
            <figure className="img-container mb-40">
              <img src="./images/choose-headers-tab.png" alt="choose-headers-tab" />
              <figcaption>Choose Headers Tab and expect to see Authorization field among headers</figcaption>
            </figure>
          </section>
        </section>
      </div>
    </>
  );
};

export default basicAuth;
