import React from 'react';
import './index.css';
import logo from '../../views/images/Solar-Manager@2x.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const TopBar = ({ authorize }) => {
  const { pathname } = useLocation();

  return (
    <div className="header-container">
      <Link to="/">
        <img src={logo} alt="Solar Manager" />
      </Link>
      <div className="authorize-container">
        {pathname === '/swagger' ? null : <button onClick={authorize}>AUTHORIZE</button>}
      </div>
    </div>
  )
}

export default TopBar;